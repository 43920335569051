import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import environment from 'src/app/app.config';
import { ContentType, RequestOptions } from '../models/http.interface';

@Injectable()
export class ApiService {
  apiURL: string;

  private _headers: HttpHeaders;
  private _defaultOptions: Record<string, any>;

  constructor(private _http: HttpClient) {
    this.apiURL = environment.apiUrl;

    this._headers = new HttpHeaders({
      'Content-Type': ContentType.Json,
      Accept: ContentType.Json
    });

    this._defaultOptions = {
      headers: this._headers,
      withCredentials: true,
      observe: 'response',
      responseType: 'text'
    };
  }

  get<T = any>(path: string, options?: RequestOptions): Observable<T> {
    return this._http
      .get<HttpResponse<T>>(this.getUrl(path), {
        ...this._defaultOptions,
        ...options
      } as Record<string, any>)
      .pipe(map(this._parseBody));
  }

  post<T = any, B = any>(path: string, body: B, options?: RequestOptions): Observable<T> {
    let requestBody: FormData | string;

    if (body instanceof FormData) {
      options = {
        ...this._defaultOptions,
        ...options,
        headers: new HttpHeaders({
          Accept: ContentType.Json,
          ...options?.headers
        })
      };
      requestBody = body;
    } else {
      requestBody = JSON.stringify(body);
    }

    return this._http
      .post<HttpResponse<T>>(this.getUrl(path), requestBody, {
        ...this._defaultOptions,
        ...options
      } as Record<string, any>)
      .pipe(map(this._parseBody));
  }

  put<T = any, B = any>(path: string, body: B, options?: RequestOptions): Observable<T> {
    let requestBody: FormData | string;
    if (body instanceof FormData) {
      options = {
        ...this._defaultOptions,
        ...options,
        headers: new HttpHeaders({
          Accept: ContentType.Json,
          ...options?.headers
        })
      };
      requestBody = body;
    } else {
      requestBody = JSON.stringify(body);
    }

    return this._http
      .put<HttpResponse<T>>(this.getUrl(path), requestBody, {
        ...this._defaultOptions,
        ...options
      } as Record<string, any>)
      .pipe(map(this._parseBody));
  }

  delete<T = any>(path: string, options?: RequestOptions): Observable<T> {
    return this._http.delete<T>(this.getUrl(path), {
      ...this._defaultOptions,
      ...options
    } as Record<string, any>);
  }

  setHeaders(headers: {}) {
    Object.keys(headers).forEach((header) => this._headers.set(header, headers[header]));
  }

  private _parseBody(response: HttpResponse<any>) {
    // if response is not a valid json object just return an empty json object.
    try {
      // handle when the response is a Blob we need to return the entire body
      if (response && response.body instanceof Blob) {
        return response.body;
        // otherwise we treat it as a JSON body
      } else {
        return JSON.parse(response.body);
      }
    } catch (e) {
      return {};
    }
  }

  private getUrl(path: string): string {
    // check if the path is a full url
    if (path.startsWith('http')) {
      return path;
    }

    return `${this.apiURL}${path}`;
  }
}
