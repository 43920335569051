import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import config from 'src/app/app.config';

import { INotification } from '../../shared/models/data-model/notifications.interface';
import { INotificationUserInfoVM } from '../../shared/models/view-model/notifications-vm.interface';

import { AuthService } from 'src/app/core/auth/auth.service';
import { ApiService } from './api.service';

@Injectable()
export class NotificationsService {
  private _url: string;
  private _stream: EventSource;
  private _notificationSubject = new BehaviorSubject<INotification>(null);
  notification$: Observable<INotification> = this._notificationSubject.asObservable();

  constructor(private _apiService: ApiService, private _authService: AuthService) {
    this._url = config.notifications.apiUrl;
  }

  async listen(): Promise<void> {
    const accessToken = await this._authService.getAccessToken();
    this._stream = new EventSource(
      `${this._url}/v1/events?applicationCode=${config.appCode}&access_token=${accessToken}`
    );

    this._stream.addEventListener('notification', (event) => {
      const data = JSON.parse((event as MessageEvent).data);
      this._notificationSubject.next(data);
    });
  }

  setAllSeen(): Observable<void> {
    return this._apiService.post(
      `${this._url}/v1/notifications/bulk-seen-all?applicationCode=${config.appCode}`,
      {}
    );
  }

  setAllClear(): Observable<void> {
    return this._apiService.post(
      `${this._url}/v1/notifications/bulk-clear-all?applicationCode=${config.appCode}`,
      {}
    );
  }

  setSeen(notificationID: number, userInfo: INotificationUserInfoVM): Observable<void> {
    return this._apiService.put(`${this._url}/v1/notifications/${notificationID}/seen`, userInfo);
  }

  setClear(notificationID: number, userInfo: INotificationUserInfoVM): Observable<void> {
    return this._apiService.put(`${this._url}/v1/notifications/${notificationID}/clear`, userInfo);
  }

  fetch(): Observable<INotification[]> {
    return this._apiService.get(`${this._url}/v1/notifications?applicationCode=${config.appCode}`);
  }
}
