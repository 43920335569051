import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from 'src/app/root-store/store/reducers';
import { getMenus, getOpenAppModules } from 'src/app/shared/helpers/layout.helper';
import { EAppModule } from 'src/app/shared/models/data-model/app-module.interface';
import {
  EBreakpoints,
  IMenuItem,
  ISubMenuItem,
  ITitleBar
} from 'src/app/shared/models/data-model/layout.interface';
import { ILayoutMap } from '../../../../shared/models/data-model/layout.interface';
import * as fromActions from '../actions';
import { LayoutActionTypes } from '../actions';
import * as fromReducers from '../reducers';
import * as fromSelectors from '../selectors';

@Injectable()
export class LayoutEffects {
  constructor(
    private _store: Store<fromReducers.GlobalState>,
    private _rootStore: Store<fromRoot.State>
  ) {}

  private _actions$: Actions = inject(Actions);

  triggerUserLayout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromActions.UserActionTypes.LoadUserPermissionsSuccessAction),
      switchMap(() => {
        return of(new fromActions.SetUserLayout());
      })
    )
  );

  initializeUserLayout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LayoutActionTypes.SetUserLayoutAction),
      withLatestFrom(
        this._store.select(fromSelectors.getUserPermissions),
        this._store.select(fromSelectors.getLayoutUserLayout),
        this._store.select(fromSelectors.getSystemInfoActiveAppModule)
      ),
      switchMap(([action, permissions, currentLayout]) => {
        const fullMenus = getMenus();
        const openModules = getOpenAppModules();
        const userLayouts: ILayoutMap = _.cloneDeep(currentLayout);

        Object.entries(userLayouts).forEach(([appModule, layout]) => {
          const userMenu: IMenuItem[] = [];

          // if it is one of the open modules, do not do anything here
          if (openModules.includes(appModule as EAppModule)) {
            return;
          }

          // if user does not have access to app module, reset layout
          if (!permissions[appModule]) {
            layout.sidebar.menu = [];
            return;
          }

          // get full menu for a given app module
          const appModuleMenu = fullMenus[appModule];
          // get permission for give app module
          const { features, featureGroups } = permissions[appModule];

          appModuleMenu.forEach((menuItem) => {
            // check on group level access
            const hasGroupAccess =
              Array.isArray(menuItem.groups) && menuItem.groups.length > 0
                ? _.intersection(Object.keys(featureGroups), menuItem.groups).length > 0
                : true;

            // check on feature level access
            const hasFeatureAccess =
              Array.isArray(menuItem.features) && menuItem.features.length > 0
                ? _.intersection(Object.keys(features), menuItem.features).length > 0
                : true;

            const hasAccess = hasGroupAccess && hasFeatureAccess;

            if (menuItem.subMenuItems) {
              // process sub-items
              const availableSubs: ISubMenuItem[] = _.filter(menuItem.subMenuItems, (subItem) => {
                return Array.isArray(subItem.features) && subItem.features.length > 0
                  ? _.intersection(Object.keys(features), subItem.features).length > 0
                  : true;
              });
              if (hasAccess && availableSubs && availableSubs.length > 0) {
                const menuItemCopy: IMenuItem = { ...menuItem, subMenuItems: availableSubs };
                userMenu.push(menuItemCopy);
              }
            } else {
              if (hasAccess) {
                userMenu.push(menuItem);
              }
            }
          });

          // assign user menu to layout
          layout.sidebar.menu = userMenu;
        });

        return of(new fromActions.SetUserLayoutComplete(userLayouts));
      })
    )
  );

  closeMenuOnMobile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      withLatestFrom(
        this._store.select(
          fromSelectors.getSystemInfoBreakpointActiveFromArray({
            breakpoints: [EBreakpoints.XS, EBreakpoints.SM]
          })
        )
      ),
      filter(([action, isMobile]) => isMobile),
      switchMap(() => {
        return of(new fromActions.HideSidebar());
      })
    )
  );

  updateTitleBarOnNavigation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      withLatestFrom(this._rootStore.select(fromRoot.getRouterState)),
      filter(([action, routerState]) => !!routerState.state.data.title),
      switchMap(([action, routerState]) => {
        const data = routerState.state.data;
        const titleBar: ITitleBar = {
          title: data.title,
          subTitle: data.subTitle ? data.subTitle : '',
          headerTag: data.headerTag ? data.headerTag : ''
        };

        return of(new fromActions.UpdateTitleBar(titleBar));
      })
    )
  );
}
