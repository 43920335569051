import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { GrowersService } from 'src/app/core/services/growers.service';
import { SnackbarService } from 'src/app/library/snackbar';
import {
  loadGrowersFailure,
  loadGrowersFromGrowerManagementPage,
  loadGrowersFromGuard,
  loadGrowersSuccess,
  updateGrower,
  updateGrowerFailure,
  updateGrowerSuccess
} from 'src/app/root-store/global-store/store/actions/growers.actions';

@Injectable()
export class GrowerEffects {
  constructor(private _growersService: GrowersService, private _snackbarService: SnackbarService) {}

  private _actions$: Actions = inject(Actions);

  loadGrowers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadGrowersFromGuard, loadGrowersFromGrowerManagementPage),
      concatMap(() =>
        this._growersService.fetchGrowers().pipe(
          map((growers) => loadGrowersSuccess({ growers })),
          catchError((error) => of(loadGrowersFailure({ error })))
        )
      )
    )
  );

  updateSingleGrower$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateGrower),
      concatMap(({ grower }) =>
        this._growersService.updateGrower(grower).pipe(
          map((updatedGrower) => {
            return updateGrowerSuccess({ grower: updatedGrower });
          }),
          catchError((error) => {
            this._snackbarService.error('Error Updating Grower');
            return of(updateGrowerFailure({ error }));
          })
        )
      ),
      catchError(() => EMPTY)
    )
  );
}
