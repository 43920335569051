import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RegionsService } from 'src/app/core/services/regions.service';
import { SnackbarService } from 'src/app/library/snackbar';
import * as fromActions from '../../store/actions';
import { LoadRegions, RegionActionTypes } from '../actions';

@Injectable()
export class RegionsEffects {
  constructor(private _snackbarService: SnackbarService, private _regionsService: RegionsService) {}

  private _actions$: Actions = inject(Actions);

  loadRegions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(RegionActionTypes.LoadRegionsAction),
      switchMap((action: LoadRegions) => {
        return this._regionsService.fetchRegions().pipe(
          map((response) => {
            return new fromActions.LoadRegionsSuccess(response);
          }),
          catchError((error) => {
            this._snackbarService.error('Error Fetching Regions');
            return of(new fromActions.LoadRegionsFail(error));
          })
        );
      })
    )
  );
}
