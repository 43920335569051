export default {
  appCode: 'PS',
  production: true,
  name: 'prod',
  apiUrl: 'https://prodsuiteapi.app.beckshybrids.com',
  socket: {
    url: 'https://prodsuiteapi.app.beckshybrids.com',
    path: '/api/socket.io'
  },
  fsSocket: {
    url: 'wss://websocket-service.app.beckshybrids.com',
    reconnect_interval: 2000,
    reconnect_interval_factor: 1.5
  },
  notifications: {
    apiUrl: 'https://notificationsapi.app.beckshybrids.com',
    socket: {
      url: 'https://notificationsapi.app.beckshybrids.com',
      path: ''
    }
  },
  authApiUrl: 'https://authn.app.beckshybrids.com/api',
  bossUrl: 'https://boss.beckshybrids.com',
  prodSuiteUrl: 'https://prodsuite.beckshybrids.com',
  okta: {
    clientId: '0oa1xri3yw1OjtB140h8',
    externalOrgUrl: 'https://mybecks.beckshybrids.com',
    internalOrgUrl: 'https://login.beckshybrids.com'
  },
  authTokenName: 'x-auth-token',
  rollbarAccessToken: 'f709b021ef9d440bac625cdfc4e0bfb4'
};
