/**
 * This abstract class is used for module building. By extending this class,
 * the target module will be prevented of being import into somewhere else than root App Module.
 *
 * @usageNotes
 *
 * ```typescript
 * @NgModule({
 *   declarations: [...],
 *   imports: [...],
 *   exports: [...],
 *   entryComponents: [...],
 *   providers: [...]
 * })
 * export class SomeModule extends ModuleImportOnceGuard {
 *     public constructor(@SkipSelf() @Optional() parent: SomeModule) {
 *         super(parent);
 *     }
 * }
 * ```
 *
 * The `@SkipSelf` annotation ensures that a parent module of the module consuming `SomeModule` gets injected by Angular DI
 * into the constructor of `SomeModule` while the `@Optional` annotation silences errors and forces the Angular DI
 * to provide `null` in case that module is not available
 * (i.e. the module we imported the `SomeModule` into is at the top of the Angular module hierarchy tree).
 *
 * The above means that if the value injected into the parent parameter is not null,
 * the module was not imported into the root application module and the code will throw a runtime exception.
 */
export abstract class ModuleImportOnceGuard {
  protected constructor(targetModule: any) {
    if (targetModule) {
      throw new Error(`${targetModule.constructor.name} has already been loaded.`);
    }
  }
}
