import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as RouterActions from '../actions/router.actions';

@Injectable()
export class RouterEffects {
  constructor(private _router: Router, private _location: Location) {}
  private _actions$: Actions = inject(Actions);

  navigate$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RouterActions.GO),
        map((action: RouterActions.Go) => action.payload),
        tap(({ path, matrixParams, query: queryParams, extras }) => {
          const pathArray = matrixParams ? [...path, { ...matrixParams }] : [...path];
          this._router.navigate(pathArray, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );

  navigateBack$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RouterActions.BACK),
        tap(() => this._location.back())
      ),
    { dispatch: false }
  );

  navigateForward$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RouterActions.FORWARD),
        tap(() => this._location.forward())
      ),
    { dispatch: false }
  );
}
