import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RegionsService } from 'src/app/core/services/regions.service';
import { SnackbarService } from 'src/app/library/snackbar';
import * as fromActions from '../actions';
import { LoadSites, SiteActionTypes } from '../actions';

@Injectable()
export class SiteEffects {
  constructor(private _snackbarService: SnackbarService, private _regionsService: RegionsService) {}

  private _actions$: Actions = inject(Actions);

  loadGrowerSites$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SiteActionTypes.LoadSitesAction),
      switchMap((action: LoadSites) => {
        return this._regionsService.fetchSites().pipe(
          map((response) => {
            return new fromActions.LoadSitesSuccess(response);
          }),
          catchError((error) => {
            this._snackbarService.error('Error Fetching Grower Sites');
            return of(new fromActions.LoadSitesFail(error));
          })
        );
      })
    )
  );
}
